<template>
  <HorizontalBarChart :chart-data="data" :options="chartOptions" />
</template>

<script>
import cloneDeep from 'clone-deep';
import HorizontalBarChart from './charts/HorizontalBarChart.vue';
import ValueParseMixin from '../mixins/ValueParseMixin.vue';

export default {
  components: { HorizontalBarChart },
  mixins: [ValueParseMixin],
  props: {
    'chart-data': { type: Array, required: true },
    'chart-title': { type: String },
  },
  computed: {
    chartOptions() {
      return {
        plugins: {
          textOnChart: {
            text: this.$store.getters.isLoading ? 'Lade...' : 'Keine Daten zum Anzeigen',
            show: this.chartData.length === 0,
          },
        },
        title: {
          display: this.chartTitle !== undefined,
          text: this.chartTitle,
        },
        legend: {
          display: false,
        },
        tooltips: {
          mode: 'x-axis',
          callbacks: {
            label: (tooltipItem, data) => this.toCurrencyString(data.datasets[0].data[tooltipItem.index], 2),
          },
        },
        // elements: {
        //   bar: {
        //     borderWidth: 2,
        //   }
        // },
        responsive: true,
        scales: {
          xAxes: [
            {
              ticks: {
                scaleShowLabels: false,
                callback: (value) => this.toCurrencyString(value, 0),
                beginAtZero: true,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                scaleShowLabels: false,
                mirror: false,
              },
            },
          ],
        },
        maintainAspectRatio: false,
      };
    },
    data() {
      const clonedData = cloneDeep(this.chartData);
      console.log(clonedData, this.chartData);

      return {
        labels: clonedData.map((store) => store.label),
        datasets: [
          {
            backgroundColor: clonedData.map((store) => store.backgroundColor),
            data: clonedData.map((store) => store.data),
          },
        ],
      };
    },
  },
};
</script>
