<script>
import { HorizontalBar, mixins } from 'vue-chartjs';

export default {
  extends: HorizontalBar,
  mixins: [mixins.reactiveProp],
  props: {
    'chart-data': {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
  watch: {
    options: {
      deep: true,
      handler() {
        this.updateChart();
      },
    },
  },
  methods: {
    updateChart() {
      console.log(this.chartData, this.options);
      this.$data._chart.options = this.options;
      this.$data._chart.update();
    },
  },
};
</script>
