<template>
  <div>
    <the-option-bar :metricItem.sync="itemKey" :dates.sync="dates" @update:dates="$emit('update:dates', $event)"></the-option-bar>

    <base-divider></base-divider>

    <v-container class="white" rounded fluid>
      <SalesChartHorizontal :chart-data="dataset" chart-title="Umsatz pro store" />
    </v-container>
  </div>
</template>

<script>
import cloneDeep from 'clone-deep';
import BaseDivider from '@/components/BaseDivider.vue';
import TheOptionBar from '@/components/TheOptionBar.vue';
import SalesChartHorizontal from '@/components/SalesChartHorizontal.vue';

export default {
  components: {
    BaseDivider,
    TheOptionBar,
    SalesChartHorizontal,
  },
  data: () => ({
    itemKey: {},
    dataset: [],
  }),
  computed: {
    selectedStores() {
      return this.$store.state.selectedStores;
    },
    storesDataResponse() {
      return Object.values(cloneDeep(this.storesChart));
    },
    storesChart() {
      return this.$store.state.storesChart;
    },
    storeNames() {
      return this.$store.state.stores;
    },
    dates: {
      get() {
        return this.$store.state.dates;
      },
      set(dates) {
        this.$store.commit('setDates', dates);
      },
    },
  },
  methods: {
    calculateAverage(dataset) {
      let total = 0;
      dataset.forEach((item) => {
        total += item.data;
      });
      const avg = total / dataset.length;
      return {
        label: 'Average',
        backgroundColor: 'red',
        data: avg,
      };
    },
    filterData() {
      if (this.storesDataResponse && this.storesDataResponse.length > 0 && this.storeNames) {
        let newDataset = [];
        if (this.selectedStores.length !== 0) {
          newDataset = this.storesDataResponse.filter((filter) => {
            return this.selectedStores.includes(filter.storeOid);
          });
        }
        newDataset = this.storesDataResponse.map((item) => {
          return {
            label: this.storeNames.find((store) => store.Oid === item.storeOid).Name,
            backgroundColor: '#f87979',
            data: item.totalGross,
          };
        });
        const averageItem = this.calculateAverage(newDataset);
        newDataset.push(averageItem);
        if (newDataset.length > 0) {
          newDataset = newDataset.sort((a, b) => b.data - a.data);
        }
        this.dataset = newDataset;
        return [];
      }
      this.dataset = [];
      return [];
    },
  },
  watch: {
    storesDataResponse() {
      this.filterData();
    },
    selectedStores() {
      this.filterData();
    },
    dates() {
      this.$store.commit('deleteStoresChart');
      this.$store.dispatch('getStoresChart');
    },
  },
};
</script>
